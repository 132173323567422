import axios from "axios";

import { ticket_endpoint } from "../../constant/api-endpoints";

let createTicket = function(params) {
    return new Promise((resolve, reject) => {
        axios
            .post(ticket_endpoint, params, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getTicket = function(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(ticket_endpoint + "/" + id, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getTickets = function() {
    return new Promise((resolve, reject) => {
        axios
            .get(ticket_endpoint, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getTicketsForCustomer = function(id) {
    return new Promise((resolve, reject) => {
        axios
            .post(ticket_endpoint + "/customer/" + id, { count: 200 })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

export default { createTicket, getTicket, getTickets, getTicketsForCustomer };
