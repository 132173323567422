import moment from "moment";

export function formatTimestamp(timestamp) {
    return (
        moment(timestamp)
            // .subtract("05:00:00")
            .format("lll")
    );
}

export function getTicketPastDay(timestamp) {
    let currentDateTime = moment();
    let duration = moment.duration(currentDateTime.diff(timestamp));
    return duration.asDays();
}

export function getDateFromTimestamp(timestamp) {
    return moment(timestamp).format("DD MMM");
}

export function isDateIncluded(startDate, endDate, createdDate) {
    if (!startDate || !endDate || !createdDate) return false;

    let startDateMoment = startDate.local();
    let endDateMoment = endDate.local();
    let createdDateMoment = moment(createdDate);

    let startDateDiff = createdDateMoment.diff(startDateMoment.startOf("day"));
    let endDateDiff = createdDateMoment.diff(endDateMoment.endOf("day"));
    return startDateDiff >= 0 && endDateDiff <= 0;
}

export default { formatTimestamp, getTicketPastDay, getDateFromTimestamp, isDateIncluded };
